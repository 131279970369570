import React from 'react';

class Radio extends React.Component {
    public render(): JSX.Element {
        return (
            <div className='badges'>
                <img src='https://www.funding.com.au/wp-content/uploads/2022/12/8-1.svg' />
                <img src='https://www.funding.com.au/wp-content/uploads/2023/02/logo-australia-broker-magazine.jpg' />
                <img src='https://www.funding.com.au/wp-content/uploads/2023/01/6-4.png' />
                <img src='https://www.funding.com.au/wp-content/uploads/2022/12/5-1.svg' />
                <img src='https://www.funding.com.au/wp-content/uploads/2022/12/3-2.svg' />
                <img src='https://www.funding.com.au/wp-content/uploads/2022/12/2-1.svg' />
                <img src='https://www.funding.com.au/wp-content/uploads/2022/12/1-1.svg' />
            </div>
        );
    }
}

export default Radio;
