import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Toasts from '~Global/Toasts';
import Logo from '~UI/Logo';
import './auth.less';

interface IProps {
    children: JSX.Element | JSX.Element[];
    section: string;
}

type Props = IProps;

class Layout extends React.Component<Props> {
    public render(): JSX.Element {
        const { section } = this.props;

        return (
            <div className='auth'>
                <Toasts/>

                <div className='d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 top-bar'>
                    <Container fluid={true}>
                        <Link to='/'><Logo className='logo' /></Link>
                    </Container>
                </div>

                <main role='main'>
                    <div className={`body ${section}`}>
                        {this.props.children}
                    </div>
                </main>

                <div className='footer-wrapper'>
                    <Container className='footer'>
                        <p>
                            <strong>DISCLAIMER</strong>
                            {' '}* Any investment rate of return and terms displayed are specific to the individual mortgages and therefore subject to the performance of that individual mortgage investment.
                            {' '}Any rate of return and income is targeted, are not guaranteed and are shown net of any fees.
                            {' '}Borrowers: Lending criteria, fees and conditions apply. For consumer or personal loans please refer to our Credit Guide for more information.
                            {' '}Australian Credit Licence ACL 483665.
                            {' '}Platform Investors:
                            {' '}The following disclaimers apply to investors in the Funding Investment Trust, ARSN 616 185 276 (“the Trust”).
                            {' '}Funding.com.au Pty Ltd ACN 603 756 547 is the manager of the Trust and authorised representative (no. 1239776) of Funding Capital Pty Ltd ACN 639 230 345 (AFSL 523 247).
                            {' '}Melbourne Securities Corporation Ltd ACN 160 326 545 (AFSL 428 289) is the trustee and responsible entity of the Trust.
                            {' '}It is important for you to read the Product Disclosure Statement (PDS) for the Trust before you make any investment decision.
                            {' '}The PDS is available on our website or by calling 1300 44 33 19.
                            {' '}You should consider carefully whether or not investing in the Trust is appropriate for you.
                            {' '}The rates of return from the Trust are targeted and not guaranteed and are determined by the future revenue of the Trust and may achieve lower than expected returns.
                            {' '}Past performance is not a reliable indicator of future performance.
                            {' '}Investors risk losing some or all of their principal investment.
                            {' '}The investment is not a bank deposit.
                            {' '}IMPORTANT: Information provided is general information only and should not be taken as legal or financial advice.
                            {' '}It does not consider the specific needs, investment objectives or financial situation of any particular investor.
                            {' '}Please refer to the <a href='https://www.funding.com.au/fsg/' target='_blank'>Financial Services Guide</a> for more information.
                            {' '}Direct Investors:
                            {' '}The direct mortgage investments do not form part of the Funding Investment Trust, ARSN 616 185 276 and the PDS does not apply to such investments.
                            {' '}See <a href='https://www.funding.com.au/privacy-policy/' target='_blank'>Privacy</a>, <a href='https://www.funding.com.au/terms-of-use/' target='_blank'>Terms</a> and <a href='https://www.funding.com.au/credit-guide/' target='_blank'>Credit Guide</a> for more details.
                        </p>
                    </Container>
                </div>
            </div>
        );
    }
}

export default Layout;
