import _ from 'lodash';
import React from 'react';
import { Container, ProgressBar } from 'react-bootstrap';
import { FiPhone } from 'react-icons/fi';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { dealBorrowStepSetAction } from '~Lead/actions';
import { borrowStepSelector } from '~Lead/selectors';
import { IGlobalState } from '~reducer';
import Badges from '~UI/Badges';
import Logo from '~UI/Logo';
import './borrow.less';

interface IProps {
    currentStep: number;
    children: JSX.Element | JSX.Element[];
    finePrint?: string;
    showProgressBar?: boolean;
}

interface IPropsSelector {
    borrowStep: number;
}

interface IPropsDispatch {
    borrowStepSet?: (borrowStep: number) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Layout extends React.Component<Props> {
    public static defaultProps: Props = {
        borrowStep: 0,
        children: null,
        currentStep: 0,
        finePrint: '📢 Our obligation free quote will not affect your credit file. Your personal information is protected. Settlements in as little as 3 to 5 days.',
        showProgressBar: true,
    };

    public componentDidMount(): void {
        const { currentStep } = this.props;

        setTimeout(() => {
            this.props.borrowStepSet(currentStep);
        }, 100);
    }

    public render(): JSX.Element {
        const { borrowStep, currentStep, children, finePrint, showProgressBar } = this.props;

        const finePrintBlock: JSX.Element = finePrint && (
            <div className='fine-print'>{finePrint}</div>
        );

        const progressbarBlock: JSX.Element = showProgressBar && (
            <ProgressBar now={borrowStep || currentStep} max={7} />
        );

        return (
            <div className='borrow'>
                <div className='top-bar'>
                    <Container fluid={true}>
                        <Link to='/'><Logo className='logo' /></Link>
                        <a href='tel:1300443319' className='phone'><FiPhone /> 1300 44 33 19</a>
                    </Container>
                </div>

                <main role='main'>
                    <div className='left-section'>
                            <div className='left-nav-line'/>
                            <div className={`step ${borrowStep === 1 ? 'active' : ''}`}>
                                <div className='step-number'>1</div>
                                My details
                            </div>
                            <div className={`step ${borrowStep >= 2 && borrowStep <= 4 ? 'active' : ''}`}>
                                <div className='step-number'>2</div>
                                My loan
                            </div>
                            <div className={`step ${borrowStep >= 5 && borrowStep <= 6 ? 'active' : ''}`}>
                                <div className='step-number'>3</div>
                                My property
                            </div>
                            <div className={`step ${borrowStep === 7 ? 'active' : ''}`}>
                                <div className='step-number'>4</div>
                                Done
                            </div>
                    </div>

                    <div className='main-section'>
                        <div className='body'>
                            <Container className='main-box'>
                                {progressbarBlock}
                                {children}
                                {finePrintBlock}
                                <Badges/>
                            </Container>
                            <Container className='box bright'>
                                <p>You&apos;re a few questions away from unlocking your custom quote!</p>
                                <p className='icon'>🎉</p>
                            </Container>
                            <Container className='box help'>
                                <h4>Need some help?</h4>
                                <p>We&apos;re here to help answer your questions or assist with your application.</p>
                                <br/>
                                <p>Call us</p>
                                <a href='tel:1300443319' className='phone'><FiPhone /> 1300 44 33 19</a>
                            </Container>
                        </div>
                    </div>
                </main>

                <div className='footer-wrapper'>
                    <Container className='footer'>
                        <p>
                            <strong>DISCLAIMER</strong>
                            {' '}* Any investment rate of return and terms displayed are specific to the individual mortgages and therefore subject to the performance of that individual mortgage investment.
                            {' '}Any rate of return and income is targeted, are not guaranteed and are shown net of any fees.
                            {' '}Borrowers: Lending criteria, fees and conditions apply. For consumer or personal loans please refer to our Credit Guide for more information.
                            {' '}Australian Credit Licence ACL 483665.
                            {' '}Platform Investors:
                            {' '}The following disclaimers apply to investors in the Funding Investment Trust, ARSN 616 185 276 (“the Trust”).
                            {' '}Funding.com.au Pty Ltd ACN 603 756 547 is the manager of the Trust and authorised representative (no. 1239776) of Funding Capital Pty Ltd ACN 639 230 345 (AFSL 523 247).
                            {' '}Melbourne Securities Corporation Ltd ACN 160 326 545 (AFSL 428 289) is the trustee and responsible entity of the Trust.
                            {' '}It is important for you to read the Product Disclosure Statement (PDS) for the Trust before you make any investment decision.
                            {' '}The PDS is available on our website or by calling 1300 44 33 19.
                            {' '}You should consider carefully whether or not investing in the Trust is appropriate for you.
                            {' '}The rates of return from the Trust are targeted and not guaranteed and are determined by the future revenue of the Trust and may achieve lower than expected returns.
                            {' '}Past performance is not a reliable indicator of future performance.
                            {' '}Investors risk losing some or all of their principal investment.
                            {' '}The investment is not a bank deposit.
                            {' '}IMPORTANT: Information provided is general information only and should not be taken as legal or financial advice.
                            {' '}It does not consider the specific needs, investment objectives or financial situation of any particular investor.
                            {' '}Please refer to the <a href='https://www.funding.com.au/fsg/' target='_blank'>Financial Services Guide</a> for more information.
                            {' '}Direct Investors:
                            {' '}The direct mortgage investments do not form part of the Funding Investment Trust, ARSN 616 185 276 and the PDS does not apply to such investments.
                            {' '}See <a href='https://www.funding.com.au/privacy-policy/' target='_blank'>Privacy</a>, <a href='https://www.funding.com.au/terms-of-use/' target='_blank'>Terms</a> and <a href='https://www.funding.com.au/credit-guide/' target='_blank'>Credit Guide</a> for more details.
                        </p>
                    </Container>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        borrowStep: borrowStepSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        borrowStepSet: (borrowStep: number) => dispatch(dealBorrowStepSetAction(borrowStep)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Layout);
