import _ from 'lodash';
import React from 'react';
import { Button, ButtonGroup, Form, Spinner } from 'react-bootstrap';
import { AiOutlineAudit, AiOutlineBank, AiOutlineTool, AiOutlineUser } from 'react-icons/ai';
import { BsHouseDoor } from 'react-icons/bs';
import { FaRegHandshake } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IDeal, { IDealErrors } from '~Api/Deal/IDeal';
import LoanPurposeEnum from '~Api/Deal/LoanPurposeEnum';
import history from '~history';
import {
    dealErrorSetAction,
    dealGetAction,
    dealValueSetAction,
} from '~Lead/actions';
import {
    dealSelector,
    errorsSelector,
    tokenSelector,
} from '~Lead/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import IToken from '~Auth/IToken';

interface ILoanPurpose {
    icon: JSX.Element;
    label: string;
}

const purposeOptions: IDictionary<ILoanPurpose> = {
    [LoanPurposeEnum.BridgingLoan]: {
        icon: <BsHouseDoor className='icon'/>,
        label: 'Bridging Loan',
    },
    [LoanPurposeEnum.Refinance]: {
        icon: <AiOutlineBank className='icon'/>,
        label: 'Refinance',
    },
    [LoanPurposeEnum.BusinessLoan]: {
        icon: <FaRegHandshake className='icon'/>,
        label: 'Business Loan',
    },
    [LoanPurposeEnum.PersonalLoan]: {
        icon: <AiOutlineUser className='icon'/>,
        label: 'Personal Loan',
    },
    [LoanPurposeEnum.RenovateOrBuild]: {
        icon: <AiOutlineTool className='icon'/>,
        label: 'Building or Renovating',
    },
    [LoanPurposeEnum.DevelopmentLoan]: {
        icon: <AiOutlineAudit className='icon'/>,
        label: 'Development Loan',
    },
};

interface IPropsSelector {
    deal: IDeal;
    errors: IDealErrors;
    borrowToken: IToken;
}

interface IPropsDispatch {
    dealGet: () => void;
    setError: (key: keyof IDealErrors, value: string) => void;
    setValue: (key: keyof IDeal, value: boolean|number|string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class LoanPurpose extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onClickContinue = this.onClickContinue.bind(this);
        this.validateLoanPurpose = this.validateLoanPurpose.bind(this);
        this.onClickLoanPurpose = this.onClickLoanPurpose.bind(this);
    }

    public componentDidMount(): void {
        const { borrowToken, deal } = this.props;

        if (borrowToken && borrowToken.dealUuid && !deal.uuid) {
            this.props.dealGet();
        }
    }

    public render(): JSX.Element {
        const { borrowToken, deal, errors } = this.props;

        if (borrowToken && borrowToken.dealUuid && !deal.uuid) {
            return (
                <Layout currentStep={4}>
                    <h3>You&apos;re already half done!</h3>
                    <Spinner animation='border' />
                </Layout>
            );
        }

        const purposeBlock: JSX.Element[] = _.keys(purposeOptions).map((key: string): JSX.Element => {
            const onClickPurpose: () => void = () => this.onClickLoanPurpose(key as LoanPurposeEnum);

            return (
                <Button className={deal.loanPurpose === key && 'active'} key={key} onClick={onClickPurpose}>{purposeOptions[key].icon}{purposeOptions[key].label}</Button>
            );
        });

        return (
            <Layout currentStep={4}>
                <h3>You&apos;re already half done!</h3>
                <p>So, what&apos;s the loan for? Click on one of the options below.</p>
                <Form>
                    <Form.Group className='loan-purpose'>
                        <ButtonGroup className='purpose-options'>
                            {purposeBlock}
                        </ButtonGroup>
                        <Form.Control type='hidden' isInvalid={!!errors.loanPurpose} readOnly={true} />
                        {errors.loanPurpose && <Form.Control.Feedback type='invalid'>{errors.loanPurpose}</Form.Control.Feedback>}
                    </Form.Group>
                </Form>
                <div className='actions'>
                    <Link className='previous' to='/get-your-quote/loan-term'><Button variant='primary'>Previous</Button></Link>
                    <Button className='continue' onClick={this.onClickContinue} variant='primary'>Continue</Button>
                </div>
            </Layout>
        );
    }

    private onClickContinue(): void {
        if (!this.validateLoanPurpose()) {
            return;
        }

        history.push('/get-your-quote/properties');
    }

    private validateLoanPurpose(): boolean {
        const { deal } = this.props;

        let error: string;

        if (!_.values(LoanPurposeEnum).includes(deal.loanPurpose)) {
            error = 'Please select your loan purpose';
        }

        this.props.setError('loanPurpose', error);

        return !error;
    }

    private onClickLoanPurpose(loanPurpose: LoanPurposeEnum): void {
        this.props.setValue('loanPurpose', loanPurpose);
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        borrowToken: tokenSelector(state),
        deal: dealSelector(state),
        errors: errorsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        dealGet: () => dispatch(dealGetAction()),
        setError: (key: keyof IDealErrors, value: string) => dispatch(dealErrorSetAction(key, value)),
        setValue: (key: keyof IDeal, value: boolean|number|string) => dispatch(dealValueSetAction(key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoanPurpose);
