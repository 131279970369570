import _ from 'lodash';
import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IDeal from '~Api/Deal/IDeal';
import IDealProperty, { IDealPropertyErrors } from '~Api/Deal/IDealProperty';
import { authRegisterErrorSetAction } from '~Auth/actions';
import history from '~history';
import {
    dealGetAction,
    propertyAddAction,
    propertyErrorSetAction,
} from '~Lead/actions';
import {
    dealSelector,
    propertiesSelector,
    savingSelector,
} from '~Lead/selectors';
import { validateProperty } from '~Lead/validators';
import { IGlobalState } from '~reducer';
import Layout from './Layout';
import Property from './Property';

interface IPropsSelector {
    deal: IDeal;
    properties: IDealProperty[];
    saving: boolean;
}

interface IPropsDispatch {
    dealGet: () => void;
    propertyAdd: () => void;
    setError: (key: string, value: string) => void;
    setPropertyError: (uuid: string, key: keyof IDealPropertyErrors, value: string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Properties extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onClickContinue = this.onClickContinue.bind(this);

        this.validateProperties = this.validateProperties.bind(this);
    }

    public componentDidMount(): void {
        const { deal } = this.props;

        if (!deal.uuid) {
            this.props.dealGet();
        }
    }

    public render(): JSX.Element {
        const { deal, properties, saving } = this.props;

        if (!deal.uuid) {
            return (
                <Layout currentStep={5}>
                    <h3>Almost done now.</h3>
                    <Spinner animation='border' />
                </Layout>
            );
        }

        return (
            <Layout currentStep={5}>
                <h3>Almost done now.</h3>
                <p>Just need some details on the current property you&apos;re using for security.</p>
                <Form className='property'>
                    {properties.map((property: IDealProperty, index: number) => <Property uuid={property.uuid} index={index} key={property.uuid} />)}
                    <Button className='add-property' disabled={saving} onClick={this.props.propertyAdd} variant='light'>
                        {!saving && 'Add Another Property +'}
                        {saving && <Spinner animation='border' as='span' role='status' size='sm' />}
                    </Button>
                </Form>
                <div className='actions'>
                    <Link className='previous' to='/get-your-quote/loan-purpose'><Button variant='primary'>Previous</Button></Link>
                    <Button className='continue' onClick={this.onClickContinue} variant='primary'>Continue</Button>
                </div>
            </Layout>
        );
    }

    private onClickContinue(): void {
        let valid = true;

        valid = this.validateProperties() && valid;

        if (!valid) {
            return;
        }

        history.push(`/get-your-quote/confirm`);
    }

    private validateProperties(): boolean {
        const { properties } = this.props;

        let valid: boolean = true;

        properties.forEach((property: IDealProperty) => {
            const setError = (key: keyof IDealPropertyErrors, value: string) => this.props.setPropertyError(property.uuid, key, value);

            valid = validateProperty(property, setError) && valid;
        });

        return valid;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        deal: dealSelector(state),
        properties: propertiesSelector(state),
        saving: savingSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        dealGet: () => dispatch(dealGetAction()),
        propertyAdd: () => dispatch(propertyAddAction()),
        setError: (key: string, value: string) => dispatch(authRegisterErrorSetAction(key, value)),
        setPropertyError: (uuid: string, key: keyof IDealPropertyErrors, value: string) => dispatch(propertyErrorSetAction(uuid, key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Properties);
