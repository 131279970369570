import React from 'react';
import { Container, Nav, NavDropdown } from 'react-bootstrap';
import { BiUser } from 'react-icons/bi';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { authLogoutAction } from '~Auth/actions';
import Toasts from '~Global/Toasts';
import Logo from '~UI/Logo';
import './borrower.less';

interface IProps {
    children: JSX.Element | JSX.Element[];
    section: string;
}

interface IPropsDispatch {
    logout: () => void;
}

type Props = IProps & IPropsDispatch;

class Layout extends React.Component<Props> {
    public render(): JSX.Element {
        const { section } = this.props;

        return (
            <div className='borrower'>
                <Toasts/>

                <div className='d-flex flex-column flex-md-row align-items-center p-3 px-md-4 top-bar'>
                    <Container fluid={true}>
                        <Nav className='auth'>
                            <NavDropdown id='auth-menu' title={<BiUser/>}>
                                <NavDropdown.Item onClick={this.props.logout}>Logout</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                        <Link to='/'><Logo className='logo' /></Link>
                    </Container>
                </div>

                <main role='main'>
                    <div className='right-section'>
                        <div className={`body ${section}`}>
                            {this.props.children}
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        logout: () => dispatch(authLogoutAction()),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(Layout);
