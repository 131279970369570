import React from 'react';
import { Button, Container, Nav, NavDropdown, Spinner } from 'react-bootstrap';
import { BiBarChartSquare, BiUser } from 'react-icons/bi';
import { FaBook, FaRegUserCircle } from 'react-icons/fa';
import { IoIosBriefcase, IoIosMenu } from 'react-icons/io';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Dispatch } from 'redux';
import IBroker from '~Api/Broker/IBroker';
import { authLogoutAction } from '~Auth/actions';
import Toasts from '~Global/Toasts';
import { IGlobalState } from '~reducer';
import Logo from '~UI/Logo';
import EmailChangeModal from '~User/EmailChangeModal';
import PasswordChangeModal from '~User/PasswordChangeModal';
import { currentBrokerGetAction } from './actions';
import './broker.less';
import { currentBrokerSelector } from './selectors';

interface IState {
    isMenuCollapsed: boolean;
}

interface IProps {
    children: JSX.Element | JSX.Element[];
    section: string;
}

interface IPropsSelector {
    currentBroker: IBroker;
}

interface IPropsDispatch {
    currentBrokerGet: () => void;
    logout: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Layout extends React.Component<Props> {
    public state: IState = {
        isMenuCollapsed: true,
    };

    private currentBrokerInterval: any;

    constructor(props: Props) {
        super(props);

        this.onClickCollapse = this.onClickCollapse.bind(this);
    }

    public componentDidMount() {
        const { currentBroker } = this.props;

        if (!currentBroker) {
            this.props.currentBrokerGet();
        }

        this.currentBrokerInterval = setInterval(() => {
            this.props.currentBrokerGet();
        }, 5 * 60 * 1000);
    }

    public componentWillUnmount() {
        clearInterval(this.currentBrokerInterval);
    }

    public render(): JSX.Element {
        const { currentBroker, section } = this.props;
        const { isMenuCollapsed } = this.state;

        if (!currentBroker) {
            return (
                <React.Fragment>
                    <Spinner animation='border' />
                </React.Fragment>
            );
        }

        return (
            <div className='broker'>
                <Toasts/>

                <div className='d-flex flex-column flex-md-row align-items-center p-3 px-md-4 top-bar'>
                    <Container fluid={true}>
                        <Nav className='auth'>
                            <NavDropdown id='auth-menu' title={<BiUser/>}>
                                <NavDropdown.Item as={NavLink} to='/my-details'>My Details</NavDropdown.Item>
                                <NavDropdown.Item onClick={this.props.logout}>Logout</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                        <Link to='/'><Logo className='logo' /></Link>
                        <Button className='left-nav-collapse' onClick={this.onClickCollapse}><IoIosMenu/></Button>
                    </Container>
                </div>

                <main role='main'>
                    <div className={`left-nav ${isMenuCollapsed ? 'collapsed' : 'open'}`}>
                        <div className='profile'>
                            <div className='name'>{`${currentBroker.firstName} ${currentBroker.lastName}`}</div>
                            <div className='credit-number'>
                                <div className='label'>Broker Ref</div>
                                <div className='value'>{currentBroker.code}</div>
                            </div>
                        </div>
                        <Nav activeKey={section} className='flex-column'>
                            <Nav.Item as={NavLink} exact={true} to='/'><BiBarChartSquare />Dashboard</Nav.Item>
                            <Nav.Item as={NavLink} to='/product-and-tools'><FaBook />Product & Tools</Nav.Item>
                            <Nav.Item as={NavLink} to='/team'><FaRegUserCircle />Team</Nav.Item>
                            <Nav.Item as={NavLink} to='/applications'><IoIosBriefcase />Applications</Nav.Item>
                        </Nav>
                    </div>

                    <div className='right-section'>
                        <div className={`body ${section}`}>
                            {this.props.children}
                        </div>
                    </div>
                </main>

                <EmailChangeModal/>
                <PasswordChangeModal/>
            </div>
        );
    }

    private onClickCollapse() {
        const { isMenuCollapsed } = this.state;

        this.setState({
            isMenuCollapsed: !isMenuCollapsed,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentBroker: currentBrokerSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentBrokerGet: () => dispatch(currentBrokerGetAction()),
        logout: () => dispatch(authLogoutAction('/broker')),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Layout);
